<template>
   <div class="row">
      <div id="cart" class="cart-card-block show p-0 col-12">
         <div class="row align-item-center">
            <div class="col-lg-8">
               <div class="card">
                  <div class="card-header d-flex justify-content-between iq-border-bottom mb-0">
                     <div class="header-title">
                        <h4 class="card-title">Shopping Cart</h4>
                     </div>
                  </div>
                  <div class="card-body">
                     <div class="checkout-product">
                        <div class="row align-items-center">
                           <div class="col-sm-2">
                              <span class="checkout-product-img">
                              <a href="javascript:void();"><img class="img-fluid rounded" src="@/assets/images/store/01.jpg" alt=""></a>
                              </span>
                           </div>
                           <div class="col-sm-4">
                              <div class="checkout-product-details">
                                 <h5>The Raze night book</h5>
                                 <p class="text-success">In stock</p>
                              </div>
                           </div>
                           <div class="col-sm-6">
                              <div class="row">
                                 <div class="col-sm-10 col-10">
                                    <div class="row align-items-center ">
                                       <div class="col-sm-7 col-md-6 col-8">
                                          <div class="quantity buttons_added">
                                                <input type="button" value="-" class="minus h5">
                                                <input type="number" step="1" min="1" max="" name="quantity" value="1" title="Qty" class="input-text qty text" size="4" pattern="" inputmode=""><input type="button" value="+" class="plus h5">
                                          </div>
                                       </div>
                                       <div class="col-sm-5 col-md-6 col-4">
                                          <span class="product-price">$15</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-2 col-2">
                                    <a href="#" class="text-dark"><i class="ri-delete-bin-7-fill h5"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-body">
                     <div class="checkout-product">
                        <div class="row align-items-center">
                           <div class="col-sm-2">
                              <span class="checkout-product-img">
                              <a href="#"><img class="img-fluid rounded" src="@/assets/images/store/02.jpg" alt=""></a>
                              </span>
                           </div>
                           <div class="col-sm-4">
                              <div class="checkout-product-details">
                                 <h5>Harsh Reality book</h5>
                                 <p class="text-success">In stock</p>
                              </div>
                           </div>
                           <div class="col-sm-6">
                              <div class="row">
                                 <div class="col-sm-10 col-10">
                                    <div class="row align-items-center ">
                                       <div class="col-sm-7 col-md-6 col-8">
                                          <div class="quantity buttons_added">
                                                <input type="button" value="-" class="minus h5">
                                                <input type="number" step="1" min="1" max="" name="quantity" value="1" title="Qty" class="input-text qty text" size="4" pattern="" inputmode=""><input type="button" value="+" class="plus h5">
                                          </div>
                                       </div>
                                       <div class="col-sm-5 col-md-6 col-4">
                                          <span class="product-price">$25</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-2 col-2">
                                    <a href="#" class="text-dark"><i class="ri-delete-bin-7-fill h5"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-body">
                     <div class="checkout-product">
                        <div class="row align-items-center">
                           <div class="col-sm-2">
                              <span class="checkout-product-img">
                              <a href="#"><img class="img-fluid rounded" src="@/assets/images/store/03.jpg" alt=""></a>
                              </span>
                           </div>
                           <div class="col-sm-4">
                              <div class="checkout-product-details">
                                 <h5>The House in the Fog</h5>
                                 <p class="text-success">In stock</p>
                              </div>
                           </div>
                           <div class="col-sm-6">
                              <div class="row">
                                 <div class="col-sm-10 col-10">
                                    <div class="row align-items-center ">
                                       <div class="col-sm-7 col-md-6 col-8">
                                          <div class="quantity buttons_added">
                                                <input type="button" value="-" class="minus h5">
                                                <input type="number" step="1" min="1" max="" name="quantity" value="1" title="Qty" class="input-text qty text" size="4" pattern="" inputmode=""><input type="button" value="+" class="plus h5">
                                          </div>
                                       </div>
                                       <div class="col-sm-5 col-md-6 col-4">
                                          <span class="product-price">$18</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-2 col-2">
                                    <a href="#" class="text-dark"><i class="ri-delete-bin-7-fill h5"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-body">
                     <div class="checkout-product">
                        <div class="row align-items-center">
                           <div class="col-sm-2">
                              <span class="checkout-product-img">
                              <a href="#"><img class="img-fluid rounded" src="@/assets/images/store/04.jpg" alt=""></a>
                              </span>
                           </div>
                           <div class="col-sm-4">
                              <div class="checkout-product-details">
                                 <h5>The badges</h5>
                                 <p class="text-success">In stock</p>
                              </div>
                           </div>
                           <div class="col-sm-6">
                              <div class="row">
                                 <div class="col-sm-10 col-10">
                                    <div class="row align-items-center ">
                                       <div class="col-sm-7 col-md-6 col-8">
                                          <div class="quantity buttons_added">
                                                <input type="button" value="-" class="minus h5">
                                                <input type="number" step="1" min="1" max="" name="quantity" value="1" title="Qty" class="input-text qty text" size="4" pattern="" inputmode=""><input type="button" value="+" class="plus h5">
                                          </div>
                                       </div>
                                       <div class="col-sm-5 col-md-6 col-4">
                                          <span class="product-price">$28</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-2 col-2">
                                    <a href="#" class="text-dark"><i class="ri-delete-bin-7-fill h5"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4">
               <div class="card">
                  <div class="card-body">
                     <p><b>Order Details</b></p>
                     <div class="d-flex justify-content-between mb-2">
                        <span>cart(4)</span>
                        <span>$86</span>
                     </div>
                     <div class="d-flex justify-content-between mb-2">
                        <span>Bag Discount</span>
                        <span class="text-success">-10$</span>
                     </div>
                     <div class="d-flex justify-content-between mb-4">
                        <span>Delivery Charges</span>
                        <span class="text-success">Free</span>
                     </div>
                     <hr>
                     <div class="d-flex justify-content-between mb-4">
                        <span class="text-dark"><strong>Total</strong></span>
                        <span class="text-dark"><strong>$76</strong></span>
                     </div>
                     <router-link :to="{name:'store.store-checkoutaddress'}" href="#" class="btn btn-primary d-block mt-3 next">Place order</router-link>
                  </div>
               </div>
               <div class="card">
                  <div class="card-body card-body p-0 iq-checkout-policy">
                     <ul class="p-0 m-0">
                        <li class="d-flex align-items-center">
                           <div class="iq-checkout-icon">
                              <i class="ri-checkbox-line"></i>
                           </div>
                           <h6>Security policy (Safe and Secure Payment.)</h6>
                        </li>
                        <li class="d-flex align-items-center">
                           <div class="iq-checkout-icon">
                              <i class="ri-truck-line"></i>
                           </div>
                           <h6>Delivery policy (Home Delivery.)</h6>
                        </li>
                        <li class="d-flex align-items-center">
                           <div class="iq-checkout-icon">
                              <i class="ri-arrow-go-back-line"></i>
                           </div>
                           <h6>Return policy (Easy Retyrn.)</h6>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div id="address" class="cart-card-block p-0 col-12">
         <div class="row align-item-center">
            <div class="col-lg-8">
               <div class="card">
                  <div class="card-header d-flex justify-content-between">
                     <div class="header-title">
                        <h4 class="card-title">Add New Address</h4>
                     </div>
                  </div>
                  <div class="card-body">
                     <form onsubmit="required()">
                        <div class="row mt-3">
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Full Name: *</label>
                                 <input type="text" class="form-control" name="fname" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Mobile Number: *</label>
                                 <input type="text" class="form-control" name="mno" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Flat, House No: *</label>
                                 <input type="text" class="form-control" name="houseno" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Landmark e.g. near apollo hospital:: *</label>
                                 <input type="text" class="form-control" name="landmark" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Town/City: *</label>
                                 <input type="text" class="form-control" name="city" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">Pincode: *</label>
                                 <input type="text" class="form-control" name="pincode" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label">State: *</label>
                                 <input type="text" class="form-control" name="state" required="">
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <label class="form-label" for="addtype">Address Type</label>
                                 <select class="form-control" id="addtype">
                                    <option>Home</option>
                                    <option>Office</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <button id="savenddeliver" type="submit" class="btn btn-primary">Save And Deliver Here</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <div class="col-lg-4">
               <div class="card">
                  <div class="card-body">
                     <h4 class="mb-2">Nik John</h4>
                     <div class="shipping-address">
                        <p class="mb-0">9447 Glen Eagles Drive</p>
                        <p>Lewis Center, OH 43035</p>
                        <p>UTC-5: Eastern Standard Time (EST)</p>
                        <p>202-555-0140</p>
                     </div>
                     <hr>
                     <a id="deliver-address" href="#" class="btn btn-primary d-block mt-1 next">Deliver To this Address</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div id="payment" class="cart-card-block p-0 col-12">
         <div class="row align-item-center">
            <div class="col-lg-8">
               <div class="card">
                  <div class="card-header d-flex justify-content-between">
                     <div class="header-title">
                        <h4 class="card-title">Payment Options</h4>
                     </div>
                  </div>
                  <div class="card-body">
                     <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-between align-items-center">
                           <img src="@/assets/images/icon/cart.png" alt="" height="40" width="50">
                           <span>US Unlocked Debit Card 12XX XXXX XXXX 0000</span>
                        </div>
                        <span>Nik John</span>
                        <span>28/2020</span>
                     </div>
                     <form class="mt-3">
                        <div class="d-flex align-items-center">
                           <span>Enter CVV: </span>
                           <div class="cvv-input ms-3 me-3">
                              <input type="text" class="form-control" required="">
                           </div>
                           <button type="submit" class="btn btn-primary">Continue</button>
                        </div>
                     </form>
                     <hr>
                     <div class="card-lists">
                        <div class="form-group">
                           <div class="form-check">
                              <input class="form-check-input" type="radio" id="credit" name="customRadio">
                              <label class="form-check-label" for="credit"> Credit / Debit / ATM Card</label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" id="netbaking" name="customRadio">
                              <label class="form-check-label" for="netbaking"> Net Banking</label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" id="emi" name="emi">
                              <label class="form-check-label" for="emi"> EMI (Easy Installment)</label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" id="cod" name="cod">
                              <label class="form-check-label" for="cod"> Cash On Delivery</label>
                           </div>
                        </div>
                     </div>
                     <hr>
                     <div class="add-card">
                        <a href="#"><span><i class="ri-add-box-line me-2 h5"></i>Add Gift Card</span></a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4">
               <div class="card">
                  <div class="card-body">
                     <h4 class="mb-2">Price Details</h4>
                     <div class="d-flex justify-content-between">
                        <span>Price 3 Items</span>
                        <span><strong>$1000.00</strong></span>
                     </div>
                     <div class="d-flex justify-content-between">
                        <span>Delivery Charges</span>
                        <span class="text-success">Free</span>
                     </div>
                     <hr>
                     <div class="d-flex justify-content-between">
                        <span>Amount Payable</span>
                        <span><strong>$1000.00</strong></span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: 'StoreCheckout-cart'
}
</script>
